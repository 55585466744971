import React, {useState, useEffect} from "react"
import Select from 'react-select'
import DatePicker from "react-datepicker"
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import "react-datepicker/dist/react-datepicker.css";
import useInterval from "../hooks/useInterval";
import axios from 'axios';

export default function Simulator(props) {
    const [simulate, setSimulate] = useState(props.simulate)
    const [idLayerSimulated, setIdLayerSimulated] = useState(0);
    const [viewLayer, setViewLayer] = useState(null);

    function simulateAction() {
        setSimulate(!simulate);
    }

    const apiClient = axios.create({
        baseURL: props.FIRE_SIMU_CDN,
        withCredentials: true,
    })

    const _updateLayer = () => {
        props.updateLayerFire(viewLayer);
        setIdLayerSimulated(0);
        //setViewLayer(response.data);
    }

    const loadJsonLayer = async(idLayerSimulated) => {
        if (simulate ) {
            console.log("/" + props.idRequest + "/" + idLayerSimulated + ".json");
            let remoteUrl  = "/" + props.idRequest + "/" + idLayerSimulated + ".json";
            if ( props.staticData) {
                remoteUrl = "/data/42/" + idLayerSimulated + ".json";

                const apiClientLocal = axios.create({
                    baseURL: "",
                    withCredentials: true,
                })

                apiClientLocal.get(remoteUrl)
                    .then((response) => {
                        console.log("response:", response.status);
                        if (response.status == 200) {
                            props._drawFireSimulation(response.data);
                            setViewLayer(response.data.fire);
                            console.log("SetViewLayer:",response.data.fire );
                            setIdLayerSimulated(idLayerSimulated + 1);
                        }
                    }).catch((e, r) => {
                    console.log("catch:", e, r);
                    setIdLayerSimulated(0);
                })
            } else {
                apiClient.get(remoteUrl)
                    .then((response) => {
                        console.log("response:", response.status);
                        if (response.status == 200) {
                            setViewLayer(response.data.fire)
                            props._drawFireSimulation(response.data);
                            setIdLayerSimulated(idLayerSimulated + 1);
                        }
                    }).catch((e, r) => {
                    console.log("catch:", e, r);
                    setIdLayerSimulated(0);
                })
            }

        }
    };

    useInterval(() => {loadJsonLayer(idLayerSimulated)}, 2000);

    return (<div>
        <div className="d-flex flex-column p-1 m-1">
            <h3>Number Simulation2 ({props.idRequest})</h3>
            <div className="d-flex justify-content-between">
                <div className="d-flex p-2">{props.dateSimulated} {props.timeSimulated}</div>
                <div className="">Layer number: <h5>{idLayerSimulated}</h5></div>
            </div>
            <button className="btn btn-block btn-warning" onClick={simulateAction}>{simulate ? "Stop": "Start"}</button>
            <button className="btn btn-block btn-success" onClick={_updateLayer}>Use this layer for new simulation</button>
        </div>
    </div>);
}
