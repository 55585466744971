import React, { useState, useEffect, useRef } from 'react';
import {MapContainer,Polyline,  TileLayer, Marker, Popup, ZoomControl, FeatureGroup, Circle, Rectangle, Polygon} from 'react-leaflet'
import { EditControl} from "react-leaflet-draw";
import axios from 'axios';
import Wizard from './comp/Wizard';
import ListLayers from "./comp/ListLayers";
import "leaflet-draw/dist/leaflet.draw.css"
import * as turf from '@turf/turf';
import Simulator from "./comp/Simulator";
import moment from "moment";

function App(props) {
    const [startPosition, setStartPosition] = useState([(props.mapDatabase2[0]+props.mapDatabase1[0])/2,(props.mapDatabase2[1]+props.mapDatabase1[1])/2]);
    const [jsonData, setJasonData] = useState([]);
    const [zoomLevelBase, setZoomLevelBase] = useState(10);
    const [drawMode, setDrawMode] = useState("AREA");
    const [drawControl, setDrawControl] = useState(null);
    const [userData, setUserData]  = useState({"area": [], "fire": []});
    const [coordinateCenterArea, setCoordinateCenterArea] = useState([]);
    const userDataRef = useRef(userData);
    const [dataSimulated, setDataSimulated] = useState(false);
    const [polyFire, setPolyFire] = useState([]);
    const [timeSimulated, setTimeSimulated] = useState(null);
    const [dateSimulated, setDateSimulated] = useState(null);
    const [idRequest, setIdRequest] = useState(0);
    const [vegMap, setVegMap] = useState(props.FIRE_SIMU_CDN_VEG_MAP);

    const [intersect, setIntersect] = useState([]);
    const LogDebugState = false;

    const rectangle = [props.mapDatabase1, props.mapDatabase2];

    useEffect(() => {
        setVegMap(props.FIRE_SIMU_CDN_VEG_MAP);
        fetch(vegMap)
            .then((res) => res.json())
            .then((data) => {
                let coord = [];
                data.mappe.map( (m) => {
                    m.coord.map( (c) => {
                        //console.log("C:", c.lat, c.lon);
                        coord.push([c.lat, c.lon]);

                    })
                    setPolyFire(coord);
                    console.log("coord:", coord);
                } );
                //console.log('data:', data);
            })
    }, [props.FIRE_SIMU_CDN_VEG_MAP])

    // aggiorna la reference al cambiamento di userData
    useEffect(() => {
        userDataRef.current = userData
    }, [userData])

    function setMapZoom(zoomLevel) {
        setZoomLevelBase(zoomLevel);
    }

    // settingDrawMode={this._changeDrawMode}
    // const _changeDrawMode = (drawMode) => {
    //     setDrawMode(drawMode);
    // }


    const _SimulationReady = (idRequest) => {
        setIdRequest(idRequest);
        setDataSimulated(true);
    }


    const _updateLayerFire = (layerData) => {
        let usData = { ...userDataRef.current};
        usData.fire = layerData;
        setUserData(prev => ({
            ...prev,
            ...usData,
        }));
        console.log("layerData:", layerData);

    }

    const _created = (e) => {
        let type = e.layerType;
        let coordinate = null;
        let latitudes = null;

        let usData = { ...userDataRef.current};

        switch (type) {
            case "polygon":
                coordinate = e.layer._latlngs[0];

                latitudes = coordinate.map((obj) => {return obj});


                let coordinateArea = Object.keys(userDataRef.current.area).map((key) => [userDataRef.current.area[key].lat, userDataRef.current.area[key].lng]);
                let coordinatePoligon = Object.keys(latitudes).map((key) => [latitudes[key].lat, latitudes[key].lng]);

                // Aggiungo il punto iniziale alla fine per rendere compatibile con l'oggetto Polygon di TURF
                coordinateArea.push(coordinateArea[0]);
                coordinatePoligon.push(coordinatePoligon[0]);

                let intersezione = turf.intersect(turf.polygon([coordinateArea]), turf.polygon([coordinatePoligon]));

                if ( intersezione) {
                    setIntersect(intersezione.geometry.coordinates);
                    let coordinateConvertToSys = [];
                    intersezione.geometry.coordinates[0].forEach(function (item) {
                        let singleCoordinate = {lat: item[0], lng: item[1]};
                        coordinateConvertToSys.push(singleCoordinate);
                    } );

                    usData.fire.push(coordinateConvertToSys);

                    setUserData(prev => ({
                        ...prev,
                        ...usData,
                    }));
                } else {
                    console.log("NO Intersection");
                }
                break;
            case "rectangle":
                coordinate = e.layer._latlngs[0];
                latitudes = coordinate.map((obj) => {return obj});
                let objLatitude = Object.keys(latitudes).map((key) => [latitudes[key].lat, latitudes[key].lng]);
                usData.area = latitudes;
                setUserData(prev => ({
                    ...prev,
                    ...usData,
                }));
                setDrawMode("FIRE");
                setCoordinateCenterArea(objLatitude);
                break;
        }
    }

    const _drawFireSimulation = (data) => {
        let polyLineMulti = data.fire.map((f) => {return f.map((d) => {return [d.lat, d.lng]})});
        //let polyLine = data.fire[data.fire.length-1].map((d) => {return [d.lat, d.lng]});
        setTimeSimulated(data.time.replaceAll("-", ":"));
        setDateSimulated(data.data.replaceAll("-", "/"));
        setPolyFire(polyLineMulti);
    }

    const _onMounted = (drawControl) => {
        setDrawControl(drawControl);
    }

    return (
        <div className="d-flex flex-row ">

            <div className="d-flex w-25 flex-column align-items-stretch">
                <div className="d-flex p-2">
                    <h3>Setup q-Simulator v 1.2</h3>
                </div>
                <div className="d-flex flex-column align-items-stretch border p-2">
                    <ListLayers userDataLayers={userData}/>
                </div>
                <div className="d-flex flex-column align-items-stretch border p-2">
                    <Wizard  simulationReady = {_SimulationReady} dataDefinition={userData} drawControl={drawControl} drawState={drawMode} coordinate={coordinateCenterArea} FIRE_SIMU_UPLOAD={props.FIRE_SIMU_UPLOAD} />
                </div>
                <div className="d-flex border flex-column align-items-stretch p-2">
                    {dataSimulated ? (<Simulator updateLayerFire={_updateLayerFire}  idRequest = {idRequest} dateSimulated={dateSimulated} timeSimulated = {timeSimulated} _drawFireSimulation = {_drawFireSimulation} dataset="42" simulate={false} FIRE_SIMU_CDN={props.FIRE_SIMU_CDN} />):(<div />)}
                </div>
                <div className="d-flex small pl-2">
                    VegMap version: {props.FIRE_SIMU_CDN_VEG_MAP}
                </div>

            </div>
            <div className="d-flex w-75">
                <MapContainer center={startPosition} zoom={zoomLevelBase} zoomControl={false} scrollWheelZoom={false} doubleClickZoom={false} inertia={true}>
                    <ZoomControl position="bottomleft" />
                    <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <FeatureGroup>
                        <EditControl
                            onMounted= {_onMounted}
                            position='topleft'
                            onCreated = {_created}
                            draw={{
                                circle: false,
                                polyline: false,
                                polygon: true,
                                rectangle: true,
                                marker: false,
                                circlemarker: false,
                            }}
                        />
                    </FeatureGroup>
                    <Polygon
                        positions={intersect}
                        color="black"
                    />
                    {polyFire ? (<Polyline background="red" color="red" positions={polyFire}  />) :""}
                    {/*{polyFire ?*/}
                    {/*    polyFire.map((f) => {console.log(f);return (<Polyline background="red" color="red" positions={f}  />) })*/}
                    {/* : (<div></div>)}*/}
                    {/*<Rectangle*/}
                    {/*    bounds={rectangle}*/}
                    {/*    color="green"*/}
                    {/*/>*/}
                </MapContainer>
            </div>
        </div>
    );
}
export default App;

