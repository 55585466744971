import React, {useState, useEffect, useTransition} from "react"
import Select from 'react-select'
import DatePicker from "react-datepicker"
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from "moment";


export default function Wizard(props) {
    const [drawMode, setDrawMode] = useState(props.settingDrawMode);
    const [startDate, setStartDate] = useState(null);
    const [durationSimulation, setDurationSimulation] = useState(0);
    const [updateSimulation, setUpdateSimulation] = useState(0);
    const [humidity, setHumidity] = useState(90);
    const [windDirection, setWindDirection] = useState("N");
    const [temperature, setTemperature] = useState(25);
    const [windSpeed, setWindSpeed] = useState(60);
    const [dataWeather, setDataWeather] = useState(null);
    const [datasetValidate, setDatasetValidate] = useState(false);
    const [awaitSimulateDataset, setAwaitSimulateDataset] = useState(false);

    const { data, isLoading, errorMessage } = useOpenWeather({
        key: '1a90da056cf7c8d5a424ffeacc0bed93',
        lat: '48.137154',
        lon: '11.576124',
        lang: 'it',
        unit: 'metric', // values are (metric, standard, imperial)
    });

    const optionsUpdateSelect = [
        { value: '5', label: '5 seconds' },
        { value: '10', label: '10 seconds' },
        { value: '25', label: '25 seconds' },
        { value: '50', label: '50 seconds' },
        { value: '120', label: '2 minute' },
        { value: '600', label: '10 minute' },
        { value: '1800', label: '30 minute' },
        { value: '3600', label: '1 hour' },
        { value: '18000', label: '5 hour' },
    ]

    const optionsDirectionWind = [
        { value: 'N', label: 'NORD' },
        { value: 'S', label: 'SUD' },
        { value: 'E', label: 'EST' },
        { value: 'O', label: 'OVEST' },
    ]

    const optionsDurationSelect = [
        { value: '600', label: '10 minutes' },
        { value: '1200', label: '20 minutes' },
        { value: '1800', label: '30 minutes' },
        { value: '2400', label: '40 minutes' },
        { value: '3000', label: '50 minutes' },
        { value: '3600', label: '1 hour' },
        { value: '7200', label: '2 hours' },
        { value: '21600', label: '6 hours' },
        { value: '43200', label: '12 hours' },
        { value: '86400', label: '1 day' },
        { value: '172800', label: '2 days' },
        { value: '432000', label: '5 days' },
    ]

    const apiClient = axios.create({
        baseURL: props.FIRE_SIMU_UPLOAD,
        withCredentials: false,
    })

    const _createArea = () => {
        setDrawMode("area");
        props.drawControl._toolbars.draw._modes.rectangle.handler.enable();
    };

    const _createFire = () => {
        setDrawMode("fire");
        props.drawControl._toolbars.draw._modes.polygon.handler.enable();
    }

    const handleDurationSelect = e => {
        setDurationSimulation(e.value);
    };

    const handleUpdateSelect = e => {
        setUpdateSimulation(e.value);
    };

    const handleHumidity = e => {
        setHumidity(e.target.value);
    };

    const handleTemperature = e => {

        setTemperature(e.target.value);
    };

    const handleWindSpeed = e => {
        setWindSpeed(e.target.value);
    };

    const handleWindDirection = e => {
        setWindDirection(e.target.value);
    };

    const _sendDataPackSimulation = () => {

        let startDateMoment = moment(startDate);
        console.log(startDateMoment);
        let idRequestGenerate = Math.floor(Math.random() * 1000);
        let copyDataDefinition = {
            ...props.dataDefinition,
            "anno": startDateMoment.year(),
            "mese": startDateMoment.month(),
            "giorno": startDateMoment.day(),
            "ora": startDateMoment.hour(),
            "minuti": startDateMoment.minutes(),
            "secondi": startDateMoment.seconds(),
            "startDate": startDate,
            "durationSimulation" : parseInt(durationSimulation),
            "updateSimulation" : parseInt(updateSimulation),
            "humidity" : parseInt(humidity),
            "windDirection" : parseInt(windDirection),
            "temperature": parseInt(temperature),
            "windSpeed" : parseInt(windSpeed),
            "state": "NS",
            "requestId": idRequestGenerate };

        apiClient.post(props.FIRE_SIMU_UPLOAD, JSON.stringify(copyDataDefinition));
        props.simulationReady(idRequestGenerate);
        //setAwaitSimulateDataset(true);
    }

    useEffect(() => {
        let validation = 0;
        if ( durationSimulation != 0)  validation += 1;
        if ( updateSimulation != 0 ) validation += 1;
        if ( startDate) validation += 1;
        if ( humidity) validation +=1;
        if ( temperature) validation +=1;
        if ( windSpeed) validation +=1;
        if ( windDirection) validation +=1;

        if (props.dataDefinition.fire.length >= 1 ) {
            validation += 1;
        }
        if (props.dataDefinition.area.length >= 1 ) {
            validation += 1;
        }
        if ( validation == 9) {
            setDatasetValidate(true);
        }

    }, [durationSimulation, updateSimulation, startDate, humidity, temperature,windSpeed,windDirection,  props.dataDefinition])

    function getMessageMode() {
        switch (drawMode) {
            case "area":
                return "Disegna un rettangolo";
            case "fire":
                return "Identifica gli incendi disegnando una poligonale";
        }
    }

        // useEffect(() => {
        //     const fetchData = async () => {
        //         let lat = coordinate.lat;
        //         let long = coordinate.lng;
        //         console.log(props.coordinate);
        //         await fetch(`${process.env.REACT_APP_API_URL}/weather/?lat=${lat}&lon=${long}&units=metric&APPID=${process.env.REACT_APP_API_KEY}`)
        //             .then(res => res.json())
        //             .then(result => {
        //                 setDataWeather(result)
        //                 console.log(result);
        //             });
        //     }
        //     fetchData();
        // }, [props.coordinate])


    useEffect(() => {
        setDataWeather(data);

        if ( isLoading) {
            console.log(data.current.wind);
        }
        console.log(isLoading);
    }, [data] )

    useEffect( () => {
        if ( isLoading) {
            console.log(dataWeather.current);
        }
    }, [dataWeather]);

    function getButtonFunction() {
        if ( props.drawState == "FIRE") {
            return (<button className="btn btn-block" onClick={_createFire}>Aggiungi incendio</button>);
        }

        if ( props.drawState == "AREA") {
            return (<button className="btn btn-block" onClick={_createArea}>Definisci area simulazione</button>);
        }
    }

    return (<div class="d-flex flex-column">
        {
            awaitSimulateDataset ? (<div>In attesa della simulazione... </div>):(<div>
                <div className="d-flex p-2 justify-content-between">
                    {getMessageMode()}
                </div>
                <div className="d-flex flex-column p-2 justify-content-between">
                    {
                        (getButtonFunction())
                    }
                </div>
                <div className="d-flex p-2 justify-content-between">
                    <label htmlFor="timeStartSimul" className="m-2 h5">Start time</label>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm"
                    />
                </div>
                <div className="d-flex p-2 justify-content-between">
                    <label className="m-2 h5">Duration time </label>
                    <Select options={optionsDurationSelect} onChange={handleDurationSelect} valu={durationSimulation}/>
                </div>
                <div className="d-flex p-2 justify-content-between">
                    <label className="m-2 h5">Update time </label>
                    <Select options={optionsUpdateSelect} onChange={handleUpdateSelect}/>
                </div>
                <div className="d-flex p-2 justify-content-between">
                    <label className="m-2 h5">Humidity (%)</label>
                    <input className="" onChange={handleHumidity} ></input>
                </div>
                <div className="d-flex p-2 justify-content-between">
                    <label className="m-2 h5">Temperature (°C)</label>
                    <input className=""  onChange={handleTemperature} ></input>
                </div>
                <div className="d-flex p-2 justify-content-between">
                    <label className="m-2 h5">Wind Speed (km/h)</label>
                    <input className="" onChange={handleWindSpeed}></input>
                </div>
                <div className="d-flex p-2 justify-content-between">
                    <label className="m-2 h5">Wind Direction (°)</label>
                    <input className="" onChange={handleWindDirection}></input>
                </div>
                <div className="d-flex flex-column p-2">
                    {datasetValidate ? (
                        <button className="btn btn-block btn-success p-2" onClick={_sendDataPackSimulation}>Start simulation</button>) : (
                        <div className="p-2 m-2 border">Completa tutti i dati per avviare la simulazione</div>)}
                </div>
            </div>
            )
        }
    </div>);
}
