import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import css from './App.css';
import reportWebVitals from './reportWebVitals';
import serialsJson from './data/serials.json';

const HTK_BACKEND = "http://qube2.hightek.it:53000";
const HTK_CDN = "http://185.203.25.236:81/v2";
const HTK_CDN_SIMU = "http://185.203.25.236:81/simu/v2/";

const FIRE_SIMU_UPLOAD = process.env.REACT_APP_FIRE_SIMU_UPLOAD || window._env_.REACT_APP_FIRE_SIMU_UPLOAD;
const FIRE_SIMU_CDN = process.env.REACT_APP_FIRE_SIMU_CDN || window._env_.REACT_APP_FIRE_SIMU_CDN;
// const REACT_FIRE_SIMU_CDN_VEG_MAP = process.env.REACT_FIRE_SIMU_CDN_VEG_MAP || window._env_.REACT_FIRE_SIMU_CDN_VEG_MAP;

console.log('FIRE_SIMU_UPLOAD:', FIRE_SIMU_UPLOAD);
console.log('FIRE_SIMU_CDN:', FIRE_SIMU_CDN);
// console.log('REACT_FIRE_SIMU_CDN_VEG_MAP:', REACT_FIRE_SIMU_CDN_VEG_MAP);

ReactDOM.render(
  <React.StrictMode>
    <App
      //startPosition = {[34.678262458,-118.923146769]}
      mapDatabase1 = {[45.084124491,  6.739786191]}
      mapDatabase2 = {[45.044320567, 6.739786191]}
      FIRE_SIMU_UPLOAD={FIRE_SIMU_UPLOAD}
      FIRE_SIMU_CDN={FIRE_SIMU_CDN}
      FIRE_SIMU_CDN_VEG_MAP={"/veg.json"}
    />
  </React.StrictMode>,
  document.getElementById('root')
);



reportWebVitals();
