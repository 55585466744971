import React, {useState, useEffect} from "react"
import {random} from "@turf/turf";

export default function ListLayers(props) {
    const [userDataLayer, setUserDataLayer] = useState(props.userDataLayers);

    useEffect(() => {
        setUserDataLayer(props.userDataLayers)

    }, [props.userDataLayers])


    return (
        <div className="p-2">
            <h3>Fire layers for new simulation {userDataLayer.fire.length ? userDataLayer.fire.length : ""}</h3>
            <div className="d-flex flex-wrap">
                {userDataLayer.fire.map((d, index) => {
                    return (<div key={index} className="m-1"><button className="btn">{index+1}</button></div>)
                })}
            </div>
        </div>
    );
}
